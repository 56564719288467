import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Menu,
  Segment,
  Header,
} from 'semantic-ui-react'

import SignOutButton from './SignOut';
import * as ROUTES from '../constants/routes';

import { AuthUserContext } from './Session';

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        <Segment inverted textAlign='center' vertical>
          <Grid columns={3} verticalAlign='middle'>
              <Grid.Column>
                <Menu.Item as={Link} to={ROUTES.LANDING}>
                  <Header as="h1" inverted>Firebox</Header>
                </Menu.Item>
              </Grid.Column>
              <Grid.Column>
                {authUser ? <NavigationAuth /> : null}
              </Grid.Column>
              <Grid.Column>
                {authUser ? <SignOutButton /> : <Link to={ROUTES.SIGN_IN}>Sign In</Link>}
              </Grid.Column>
          </Grid>
        </Segment>
        
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = () => (
  <Menu inverted stackable secondary>
    <Menu.Item as={Link} to={ROUTES.HOME}>Home</Menu.Item>
    <Menu.Item as={Link} to={ROUTES.ACCOUNT}>Account</Menu.Item>
    <Menu.Item as={Link} to={ROUTES.ADMIN}>Admin</Menu.Item>
  </Menu>
);

export default Navigation;
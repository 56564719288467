import React from 'react';
import {
    Grid,
    Segment,
  } from 'semantic-ui-react'

const PageContent = (props) => (
    <Segment className="padded-content" vertical>
        <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
            <Grid.Column width={12}>
                {props.children}
            </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
)

export default PageContent;
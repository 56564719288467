import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';

import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <App />
    </FirebaseContext.Provider>
  , document.getElementById('root'));

// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/

// https://medium.com/@thevatsalsaglani/working-with-firebase-real-time-database-using-reactjs-and-uikit-and-launching-to-netlify-ff92419289b2
// https://rusl.io/blog/netlify-firebase-env-vars/

// SEO
// https://medium.com/@lovetostrikep/aurelia-firebase-netlify-bce5f3995f36